<template>
  <div class="body">
    <top></top>

    <!-- 广告图 -->
    <div class="advertisement_box">
      <div class="advertisement">
        <img :src="imga" alt="" />
      </div>
    </div>
    <!-- 搜索框 -->
    <div :class="scrollTop > 150 ? 'fixedSearch' : ''" class="seek">
      <div :class="scrollTop > 150 ? 'fixedSearchNext' : ''" class="seek-input">
        <div class="seek-box">
          <div class="seek-one">
            <div class="seek-one-icon">
              <img src="../assets/home/seek.png" alt="" />
            </div>
            <div class="seek-one-input">
              <input
                type="font"
                placeholder="搜索您想要的内容"
                v-model="keywords"
                @keyup.enter="toSearch"
              />
            </div>
            <div class="seek-one-button" @click="toSearch">
              <button @click="toSearch">搜索</button>
            </div>
          </div>
        </div>
        <!-- 搜索框下面的文字  :class="scrollTop > 150 ? 'disNone' : ''"-->
        <div class="seek_input_font">
          <ul>
            <li
              v-for="(item, index) in Populartags"
              :key="index"
              @click="hotTg(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 当前位置 -->
    <div class="place_box">
      <div class="place">
        <div class="place_image">
          <img src="../assets/Search_page/109.png" alt="" />
        </div>
        <!-- 位置 -->
        <div class="place_font">
          <p>当前位置：</p>
          <p>
            <span @click="toIndex">我要短剧本</span>
            >短文
          </p>
          <!-- <p>></p>
          <p>短文</p> -->
        </div>
      </div>
    </div>
    <!-- 分类 -->
    <div class="classify_list">
      <div class="classify_box">
        <span> 分类：</span>
        <div>
          <span
            @click="selectClassifyAll"
            :class="activeIndex == -1 ? 'btnAtivetwo' : ''"
            >全部</span
          >
          <span
            v-for="(item, index) in btns"
            :key="index"
            :class="activeIndex == index ? 'btnAtivetwo' : ''"
            @click="handleToggle(index, item.id, item.name)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <!-- 已选 -->
    <div class="classify_list classify_list_a">
      <div class="classify_box">
        <span> 已选：</span>
        <div class="aaaaa">
          <button class="shop">
            <span>分类：{{ className }}</span>
            <p @click="yincang" v-show="classNameState">✖</p>
          </button>
       
        </div>
      </div>
    </div>
    <!-- 主体内容 -->
    <div class="body_content_box">
      <!-- 内容 -->
      <div class="body_content" v-if="isHaveData">
        <div
          class="box"
          v-for="item in arr"
          :key="item.id"
          @click="toText(item.id)"
        >
          <div class="tab-1">
            <!-- 标题 -->
            <div class="tab-title-font">
              <!-- 前面的竖线 -->
              <div class="tab-one"></div>
              <div class="tab-title">
                <p>{{ item.title }}</p>
              </div>
            </div>
            <!-- 文字内容 -->
            <div class="tab-font">
              <p
                v-html="colorkeyword(item.description)"
                v-if="item.description != null && item.description != ''"
              ></p>
              <p v-html="colorkeyword(item.content)" v-else></p>
            </div>
            <!-- 标签 -->
            <div class="label">
              <div v-for="(itema, index) in item.tagestitle" :key="index">
                {{ itema.name }}
              </div>
            </div>
             <div class="moreBtn">查看更多</div>
            <!-- 个人信息 -->
            <div class="personal">
              <p><img src="../assets/home/1.png" alt="" /></p>
              <p>{{ item.useradmin }}</p>
              <p>{{ item.updated_at }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="body_content dataNone" v-else>
        <img src="../../public/images/datanone.png" alt="" />
        <p>未找到相关内容</p>
      </div>
      <!-- 分页 -->
      <div class="paging" v-show="isHaveData">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          hide-on-single-page
          background
          layout="prev, pager, next"
          :page-count="arr1.total_page"
          :pager="arr1.page_size"
          :current-page.sync="current_page"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 广告 -->
    <div class="advertising_boxs" v-show="isShow">
      <div class="advertising">
        <button class="occlude" @click="onclusa">×</button>
        <!-- 图片 -->
        <div class="advertising_image">
          <i><img src="../assets/home/vip.png" alt="" /></i>
        </div>

        <!-- 按钮 -->
        <div class="advertising_button">
          <button @click="kaitong">开通VIP</button>
        </div>
      </div>
    </div>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom></bottom>
    <loginM ref="loginM"></loginM>
  </div>
</template>

<script>
    import Qs from "qs";
    import axios from "axios";
    import loginM from "../components/loginM";
    import {
        essaySearch,
        getessayimg,
        getessayclassify,
        getessaytage,
        Populartags,
    } from "../http.js";

    export default {
        components: {
            loginM
        },
        data() {
            return {
                current_page: 1,
                isHaveData: true,
                scrollTop: 0,
                btns: [],
                activeIndex: -1,
                keywords: "",
                shopp: true,
                // qaqa: true,
                qaqb: true,
                qaqc: true,
                qaqd: true,
                qaqe: true,
                qaqf: true,
                qaqg: true,
                qaqh: true,
                qaqi: true,
                imga: [],
                arr: [],
                arr1: [],
                p: 1,
                tag: "",
                className: "全部",
                classNameState: false,
                valuea: {},
                isShow: false, //vip弹窗显隐
                Populartags: [], //热门段子
            };
        },
        // /页面初始化
        created() {
            this.valuea = JSON.parse(sessionStorage.getItem("key"));
            // 监听页面滚动事件
            window.addEventListener("scroll", this.showSearch);
        },
        watch: {
            $route: {
                async handler() {
                    this.countChange();
                },
                deep: true,
            },
        },
        mounted() {
            this.essayimg();
            this.essayclassify();
            this.essaytage();
            //通过count改变
            this.countChange();
            // 热门标签调用
            this.hotTag();
        },
        methods: {
            // 热门标签点击事件
            hotTg(item) {
                this.keywords = item;
                this.colorkeyword(item);
                this.toSearch();
            },
            // 热门标签
            async hotTag() {
                let res = await Populartags();
                console.log("230", res);
                if (res.static == 1) {
                    this.Populartags = res.data;
                    console.log(this.Populartags);
                }
            },
            //吸顶监听
            showSearch() {
                // 获取当前滚动条向下滚动的距离
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // 当页面滚动到高度300px处时，显示搜索框
                // console.log(scrollTop);
                this.scrollTop = scrollTop;
            },
            // count处理
            async countChange() {
                console.log(this.$route.query.count);
                if (this.$route.query.count >= 0) {
                    let count = this.$route.query.count;
                    await this.essayclassify();
                    let changeIndex = this.btns.findIndex((item) => item.id == count);
                    this.activeIndex = changeIndex;
                    this.tag = this.btns[changeIndex].id;
                    this.className = this.btns[changeIndex].name;
                    await this.essaytage(this.p, this.tag);
                } else {
                    this.selectClassifyAll();
                    return;
                }
            },
            //去详情页
            async toText(id) {
                let that = this;
                console.log(this.valuea);
                this.tabAcitve(id);
                return
                // 没有扫过码
                if (that.valuea == null) {
                    that.$refs.loginM.qq();
                }

                // 扫码过没有注册账号
                if (that.valuea.uid == "" || that.valuea.phone == "") {
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.$refs.loginM.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.$refs.loginM.erbtna = false;
                    that.$refs.loginM.erbtn = true;
                    // 手机号显示结束
                    return;
                }

                //普通会员
                if (this.valuea.class == 0) {
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: "/api/shopmini/wap_UserContents.html",
                        async: false, //同步请求
                        data: {
                            uid: that.valuea.uid,
                            login_key: that.valuea.openid
                        },
                        success: function(res) {
                            console.log(res)
                            if (res.snum > 0) {
                                that.tabAcitve(id);
                                return;
                            }
                            if (res.snum == 0) {
                                // 判断普通会员用户观看次数
                                that.$message({
                                    message: "今日浏览次数已用完",
                                    type: "warning",
                                });
                                that.isShow = true; //vip弹框显示
                                return;
                            };
                        },
                        error: function(data) {
                            alert("正在加载请稍后！");
                        }
                    });
                }

                //非普通会员无限看
                if (this.valuea.class > 0) {
                    this.tabAcitve(id);
                }
            },

            // 开通vip弹窗确定按钮
            kaitong() {
                this.$router.push({
                    path: "/vip",
                });
            },
            // 关闭vip弹窗
            onclusa() {
                this.isShow = false;
            },
            // tab跳转
            tabAcitve(id) {
                //选项卡跳页面开始
                let goNewPath = this.$router.resolve({
                    name: "Text",
                    query: {
                        page: 2,
                        id,
                    },
                });
                window.open(goNewPath.href, "_blank");
                // 选项卡结束
            },

            // 短文栏目图
            async essayimg() {
                var img = await getessayimg();
                this.imga = img.data;
            },
            // 短文分类
            async essayclassify() {
                var classifya = await getessayclassify();
                this.btns = classifya.data;
                console.log(classifya);
            },
            // 短文分类搜索
            async essaytage(p, tag) {
                this.tag = tag;
                console.log(this.tag);
                var tage = await getessaytage(p, this.tag);
                console.log(tage);
                if (tage.stutic == 1) {
                    this.isHaveData = true;
                    console.log(this.arr);
                    this.arr = tage.data;
                    this.arr1 = tage.pager;
                    console.log(this.arr, this.arr1);
                } else {
                    this.isHaveData = false;
                    console.log(tage.msg);
                }

                // console.log(tage);

                // this.p=val;
                // let shortplaydata=await getessaytage(this.p,this.tag);
                // this.shortplaydata=shortplaydata;
            },

            // 分页
            handleSizeChange(val) {
                this.p = val;
                console.log(`每页 ${val} 条`);
                this.essaytage(val, this.tag);
            },
            handleCurrentChange(val) {
                this.p = val;
                this.essaytage(val, this.tag);
                console.log(`当前页: ${val}`);
                // console.log(111);
            },
            // 分类点击按钮
            async handleToggle(index, tag, name) {
                this.current_page = 1;
                this.p = 1;
                this.classNameState = true;
                this.className = name;
                this.tag = tag;
                this.activeIndex = index;
                this.essaytage(this.p, tag);
                console.log(tag);
                // var tage = await getessaytage(this.p, this.tag);
                // console.log(tage);
            },

            yincang() {
                // this.qaqa = true;
                this.selectClassifyAll();
            },
            //搜索数据
            async toSearch() {
                let keywords = this.keywords;

                if (keywords.trim().length <= 0) {
                    alert("请输入内容");
                    return;
                }
                this.$router.push({
                    path: "/Search_page",
                    query: {
                        keywords,
                    },
                });
            },
            //去首页
            toIndex() {
                this.$router.push({
                    path: "/Index",
                });
            },
            //选择全部
            selectClassifyAll() {
                this.p = 1;
                this.current_page = 1;
                this.activeIndex = -1;
                this.className = "全部";
                this.tag = "";
                this.essaytage(this.p, this.tag);
                this.classNameState = false;
            },
        },
        destroyed() {
            window.removeEventListener("scroll", this.showSearch);
        },
    };
</script>

<style scoped>
    /* 广告 */
    
    .advertising_boxs {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        /* display: none; */
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
        /* background-color: red; */
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    
    .occlude {
        font-size: 25px;
        line-height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 50px;
        top: 80px;
    }
    /* 广告结束 */
    /* input吸顶效果 */
    
    .fixedSearch {
        position: fixed;
        z-index: 2;
        height: 82px !important;
        background: rgb(255, 255, 255);
        top: 60px;
    }
    
    .fixedSearchNext {
        height: 90px !important;
    }
    
    .disNone {
        display: none;
    }
    /* 吸顶效果结束 */
    /* 页面 */
    
    .body {
        width: 100%;
        min-height: 100vh;
        min-width: 1200px;
        background-color: #fafafa;
    }
    /* 广告 */
    
    .advertisement_box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding-top: 70px;
        background-color: #ffffff;
    }
    
    .advertisement {
        width: 1200px;
        margin: auto;
    }
    
    .advertisement img {
        width: 1200px;
        margin: auto;
    }
    /* 搜索 */
    
    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
        background-color: #ffffff;
    }
    
    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    
    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }
    
    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }
    
    .seek-one-icon img {
        width: 100%;
    }
    
    .seek-one-input {
        width: 590px;
        display: inline-block;
    }
    
    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }
    
    .seek-one-input input:checked {
        color: #999999;
    }
    
    .seek-one-button {
        width: 114px;
        height: 40px;
    }
    
    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }
    
    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }
    
    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }
    
    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }
    
    .seek_input_font ul li:hover {
        color: #f62959;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
        display: flex;
    }
    /* 图标 */
    
    .place_image img {
        vertical-align: middle;
    }
    /* 位置文字 */
    
    .place_font {
        display: flex;
    }
    
    .place_font p {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
    }
    
    .place_font p:nth-of-type(2),
    .place_font p:nth-of-type(4) {
        cursor: pointer;
    }
    
    .classify_list {
        width: 100%;
        min-width: 1200px;
        background-color: #ffffff;
        color: #333333;
        padding: 10px 0 10px 0;
    }
    
    .classify_list_a {
        margin-top: 30px;
    }
    
    .classify_box {
        width: 1200px;
        /* height: 80px; */
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 18px;
        overflow: hidden;
        /* margin-top: 20px; */
        /* border: 1px solid red; */
    }
    
    .classify_box>span {
        line-height: 66px;
        display: block;
    }
    
    .shop {
        /* background-attachment: red; */
        background-color: rgb(240, 243, 245);
        /* background-color: green; */
        width: 155px;
        height: 40px;
        border-radius: 15px;
        font-size: 15px;
        color: gray;
        position: relative;
        margin-left: 10px;
        margin-top: 10px;
        /* display: none; */
    }
    
    .qaqdd {
        display: none;
    }
    
    .shop span {
        margin-left: -25px;
    }
    
    .shop p {
        width: 20px;
        height: 20px;
        background-color: rgb(155, 152, 152);
        position: absolute;
        left: 125px;
        top: 10px;
        border-radius: 10px;
    }
    
    .classify_box>div {
        width: 95%;
        display: flex;
    }
    
    .classify_box>div>span {
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid transparent;
    }
    
    .aaaaa {
        /* background-color: red; */
        display: flex;
        flex-wrap: wrap;
    }
    
    .btnAtivetwo {
        border: 1px #f62959 solid !important;
        /* box-sizing: border-box; */
        /* background-color: #F62959; */
        box-sizing: border-box;
        color: #f62959;
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
    }
    /* 主体内容 */
    
    .body_content_box {
        width: 100%;
        min-height: 350px;
        max-height: 1200px;
        min-width: 1200px;
        position: relative;
        /* padding-bottom: 50px; */
    }
    /* 内容的框 */
    
    .body_content {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    
    .dataNone {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 34px;
        font-size: 16px;
    }
    /* 内容 */
    
    .tab-1 {
        margin: 20px 20px 0px 0px;
        padding-left: 10px;
        padding-top: 2px;
        width: 380px;
        border-radius: 8px;
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.08);
        box-sizing: border-box;
        transition: 0.3s;
        position: relative;
    }
    
    .tab-1:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    
    .tab_image {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab_image img {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab-title>p:hover {
        color: #f62959;
    }
    
    .tab-title-font {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-top: 10px;
    }
    
    .tab-one {
        width: 6px;
        height: 22px;
        border-radius: 25px;
        vertical-align: middle;
        margin: 0px 10px 0px 10px;
        background-color: #f62959;
    }
    
    .tab-title {
        vertical-align: middle;
        font-size: 20px;
        color: #333333;
    }
    /* 文字内容 */
    
    .tab-font {
        margin: auto;
        width: 86%;
        height: 66px;
        overflow: hidden;
        /* background-color: tan; */
        font-size: 14px;
        color: #666666;
    }
    
    .tab-font p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    /* 标签 */
    
    .label {
        width: 86%;
        height: 30px;
        /* background-color: tan; */
        margin: auto;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #fee9ee solid;
    }
    
    .label div {
        padding: 3px 8px 3px 8px;
        display: inline-block;
        background-color: #fff7f2;
        color: #ff5c0d;
        border-radius: 2px;
        margin: 4px;
    }
    /* 个人信息 */
    
    .personal {
        width: 86%;
        /* background-color: red; */
        margin: auto;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
    }
    
    .personal p:nth-of-type(1) {
        margin: 0px 10px 0px 10px;
    }
    
    .personal img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .personal p:nth-of-type(2) {
        width: 30%;
        /* background-color: red; */
    }
    
    .personal p:nth-of-type(3) {
        width: 60%;
        text-align: right;
        /* background-color: tan; */
    }
    /* 底部信息 */
    
    .tab_content_box {
        padding-left: 10px;
        color: #333333;
    }
    
    .tab_content_bottom {
        display: flex;
        font-size: 14px;
    }
    
    .tab_content_left {
        width: 76%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab_content_right {
        width: 20%;
        text-align: right;
    }
    /* 分页 */
    
    .paging {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<!-- 分页 -->
<style>
    .el-pager li {
        font-size: 12px;
        width: auto !important;
        height: auto !important;
    }
    
    .active {
        border: none !important;
        border-radius: 50% !important;
        background-color: #f62959 !important;
    }
    
    .number:hover {
        color: #fff !important;
        background-color: #f62959 !important;
    }
    
    .number {
        border-radius: 50% !important;
        border: 1px #999 solid !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    /* 下一页 */
    
    .btn-next>i::before {
        content: "下一页" !important;
    }
    
    .moreBtn:hover {
        color: #f62959;
    }
    
    .moreBtn {
        position: absolute;
        width: 100%;
        right: 0;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
        color: rgb(180, 180, 180);
        margin-top: -20px;
        font-size: 14px;
        background: -moz-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
    }
</style>